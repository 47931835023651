import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'

import ArrowForward from '../components/Icons/ArrowForward'
import Layout from '../components/layout'
import PageHeader from '../components/page-header'
import Block from '../components/waypoint-block'
import Drift from '../components/waypoint-drift'
import ColorSwitch from '../components/waypoint-color-switch'
import NewsletterSignup from '../components/newsletter-signup'

function mapEventDates(events, locale) {
  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  })

  return events.map(event => {
    let date = event.date
    let isoDate = null
    if (date !== null) {
      const parsedDate = new Date(event.date)
      const rawParts = formatter.formatToParts(parsedDate)
      let parts = {}
      rawParts.forEach(part => {
        parts[part.type] = part.value
      })
      date = `${parts.month} ${parts.day}, ${parts.year}`

      isoDate = parsedDate.getTime()
    }

    return {
      ...event,
      date,
      isoDate,
    }
  })
}

function EventsPage({ data }) {
  const [t, { language }] = useTranslation(['translations', 'seo'])
  const eventsPage = data.prismicEvents?.data

  if (!eventsPage) {
    return null
  }

  const currentEvents = mapEventDates(
    eventsPage.current_events.map(({ event }) => event.document.data),
    language
  )
  const upcomingEvents = mapEventDates(
    eventsPage.upcoming_events.map(({ event }) => event.document.data),
    language
  )

  const today = Date.now()

  return (
    <React.Fragment>
      <Helmet
        title={t('EventsPage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('EventsPage.description', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('EventsPage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('EventsPage.ogTitle', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('EventsPage.ogDescription', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-thesis.jpg',
          },
          { property: 'og:url', content: 'https://versett.com/events/' },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Events' },
        ]}
      >
        <html lang={language} />
      </Helmet>
      <Layout>
        <div className="s-events">
          <section className="s-container">
            <PageHeader
              lede={eventsPage.heading}
              subheading={eventsPage.subheading}
            />
          </section>
          <Drift />
          <section className="s-container s-events__events">
            {currentEvents.map((event, index) => (
              <Block key={index}>
                <figure className="grid grid--2col-1-2">
                  <div />
                  <div>
                    <p>{event.date ? event.date : 'TBD'}</p>
                    <h4>{event.heading}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: event.description.html,
                      }}
                      className="color-secondary"
                    />
                    <div>
                      {event.prereads[0].title && (
                        <div>
                          <div className="color-secondary">
                            {t('EventsPage.preReads')}
                          </div>
                          <ul>
                            {event.prereads.map((preread, index) => (
                              <li>
                                <a key={index} href={preread.url}>
                                  {preread.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {event.prelistens[0].title && (
                        <div>
                          <div className="color-secondary">
                            {t('EventsPage.preListens')}
                          </div>
                          <ul>
                            {event.prelistens.map((prelisten, index) => (
                              <li>
                                <a key={index} href={prelisten.url}>
                                  {prelisten.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div>
                        <span className="color-secondary">
                          {t('EventsPage.location')}
                        </span>
                        {event.location}
                      </div>
                      <div>
                        <span className="color-secondary">
                          {t('EventsPage.time')}
                        </span>
                        {event.event_start}
                      </div>
                    </div>
                    {today < event.isoDate || !event.isoDate ? (
                      <div className="s-events__events__register">
                        {event.register[0].title && (
                          <div>
                            <ul>
                              {event.register.map((reg, index) => (
                                <li>
                                  <a key={index} href={reg.url}>
                                    {reg.title}
                                  </a>{' '}
                                  <ArrowForward />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="s-events__events__register">
                        {event.post_event_resources.length > 0 &&
                        event.post_event_resources[0].title ? (
                          <div>
                            <ul>
                              {event.post_event_resources.map((reg, index) => (
                                <li>
                                  <a key={index} href={reg.url}>
                                    {reg.title}
                                  </a>{' '}
                                  <ArrowForward />
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          t('EventsPage.expiredEvent')
                        )}
                      </div>
                    )}
                  </div>
                </figure>
              </Block>
            ))}
            <Block>
              <figure className="grid grid--2col-1-2 s-events__upcoming">
                <div>Upcoming</div>
                <div>
                  <ul>
                    {upcomingEvents.map((event, index) => (
                      <li key={index}>
                        <span className="color-secondary">
                          {event.date ? event.date : 'TBD'} -{' '}
                        </span>
                        {event.heading}
                      </li>
                    ))}
                  </ul>
                  <a href="#newsletter">{t('EventsPage.newsLetter')}</a>
                </div>
              </figure>
            </Block>
          </section>
          <ColorSwitch />
          <section>
            <Block>
              <section className="s-container s-events__image">
                <picture className="c-image c-image--fullscreen">
                  <source
                    media="(max-width: 767px)"
                    srcSet="/images/presenting-01-vert.jpg"
                  />
                  <img
                    src="/images/presenting-01.jpg"
                    alt="Steven presenting to a group."
                  />
                </picture>
              </section>
            </Block>
          </section>
          <div className="s-events__descriptions">
            {eventsPage.descriptions.map((description, index) => (
              <Block>
                <section
                  key={index}
                  className="s-container grid grid--2col-1-2"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${description.title.html}<span></span>`,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${description.body.html}`,
                    }}
                    className="color-secondary"
                  />
                </section>
              </Block>
            ))}
          </div>
          {/* <div id="newsletter" className="s-events__newsletter">
            <NewsletterSignup
              list="https://versett.us6.list-manage.com/subscribe/post?u=a134cd0693&id=0f6cd9387c"
              heading={t('HomePage.LatestNewsSection.lede')}
              lede={t('HomePage.LatestNewsSection.heading')}
              placeholder={t('HomePage.LatestNewsSection.placeholder')}
            />
          </div> */}
        </div>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query eventsQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicEvents(lang: { eq: $language }) {
      data {
        heading
        subheading
        descriptions {
          title {
            html
          }
          body {
            html
          }
        }
        current_events {
          event {
            document {
              ... on PrismicEvent {
                data {
                  heading
                  type
                  event_number
                  description {
                    html
                  }
                  date
                  event_start
                  location
                  prereads {
                    title
                    url
                  }
                  prelistens {
                    title
                    url
                  }
                  register {
                    title
                    url
                  }
                  post_event_resources {
                    title
                    url
                  }
                }
              }
            }
          }
        }
        upcoming_events {
          event {
            document {
              ... on PrismicEvent {
                data {
                  heading
                  type
                  event_number
                  date(formatString: "mmm")
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventsPage
